<template>
  <div class="conceptEmployment">
    <div class="ce-theme">{{ recruitContent.title }}</div>
    <div class="conceptEmployment-introduce">
      <div class="ce-word">
        <div class="ce-text" v-html="recruitContent.richText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapState } from 'vuex'

export default {
  name: "",
  data() {
    return {
      recruitContent: {},
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
             titleId: this.nodeId[this.$route.query.titleId] || this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.recruitContent = res.data.data.pageDatas[0];
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {
    ...mapState(['nodeId']),
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.conceptEmployment {
  width: 100%;
  .ce-theme {
    text-align: center;
    font-size: 24px;
    color: #363636;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .conceptEmployment-introduce {
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    .ce-word {
      padding: 50px;
      box-sizing: border-box;
      .ce-text {
        font-size: 15px;
        line-height: 30px;
        color: #4d4d4d;
      }
    }
  }
}
</style>
